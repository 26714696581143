import { BehaviorSubject, map, Subject } from 'rxjs';
import { PermissionsHelper } from './permissions.helper';
import { calculateRoleFromLegacyPermissions, roleCheck, UserRole } from './constants';
export class OnSessionEnd {
  constructor() {}
}
export class OnSessionStart {
  constructor(sessionInfo) {
    this.sessionInfo = sessionInfo;
  }
}
export class OnSessionReloaded {
  constructor(sessionInfo) {
    this.sessionInfo = sessionInfo;
  }
}
export class OnInvalidSessionId {
  constructor() {}
}
export class BaseAccessService {
  constructor() {
    this.events = new Subject();
    this._sessionId = null;
    // NOTE: sessionInfo is populated by platform-specific child AccessServices!
    this._sessionInfo$ = new BehaviorSubject(null);
    this.accessServicePlugins = null;
  }
  get sessionId() {
    return this._sessionId;
  }
  get sessionInfo() {
    return this._sessionInfo$.value;
  }
  get role() {
    return this._sessionInfo$.value?.role || UserRole.GUEST;
  }
  get isLoggedIn() {
    return this._sessionInfo$.value !== null;
  }
  get isRegistered() {
    return this.hasRole('REGISTERED');
  }
  get isAdmin() {
    return this.hasRole('ADMINISTRATOR');
  }
  get isSU() {
    return this.hasRole('SUPERUSER');
  }
  get permissions$() {
    return this._sessionInfo$.pipe(map(info => info?.permissions || []));
  }
  get permissions() {
    return this._sessionInfo$.value?.permissions || [];
  }
  get activatedFeatures$() {
    return this._sessionInfo$.pipe(map(info => info?.activatedFeatures || []));
  }
  get activatedFeatures() {
    return this._sessionInfo$.value?.activatedFeatures || [];
  }
  setupPlugins(pluginCtors, injector) {
    if (!pluginCtors?.length) throw new Error('BaseAccessService error: no AccessServicePlugins were found. Aborting setup.');
    this.accessServicePlugins = pluginCtors.map(ctor => {
      const plugin = new ctor(injector);
      if (plugin.initialize) plugin.initialize(this);
      return plugin;
    });
  }
  // "facade" exposing some contents of AccessServicePlugin 
  getAccessData(name, extraParam = null, handleUnknown = 'null') {
    const accessorInfo = this.findDataAccessor(name, handleUnknown);
    if (accessorInfo === null) return null;
    return this.executeDataAccessor(accessorInfo.plugin, accessorInfo.accessor, this._sessionInfo$.value, extraParam);
  }
  getAccessData$(name, extraParam = null, handleUnknown = 'null') {
    const accessorInfo = this.findDataAccessor(name, handleUnknown);
    if (accessorInfo === null) return null;
    return this._sessionInfo$.pipe(map(info => {
      return this.executeDataAccessor(accessorInfo.plugin, accessorInfo.accessor, info, extraParam);
    }));
  }
  executeDataAccessor(plugin, accessor, sessionInfo, extraParam) {
    try {
      if (!sessionInfo) return null;
      const getPluginData = pluginId => {
        if (!pluginId) pluginId = plugin.pluginId;
        // in case data of the requested plugin is unavailable, return null.
        if (!sessionInfo.accessData[pluginId]) return null;
        return sessionInfo.accessData[pluginId];
      };
      return accessor(getPluginData, sessionInfo, extraParam);
    } catch (err) {
      /**
       * without catching, it may look like errors happen somewhere inside component templates.
       * to keep application intact, we catch errors, log a warning and return null instead.
       */
      console.warn('Error while executing DataAccessor: ' + err);
      return null;
    }
  }
  findDataAccessor(name, handleUnknown = 'null') {
    for (const plugin of this.accessServicePlugins) {
      if (plugin.dataAccessors[name]) {
        return {
          plugin,
          accessor: plugin.dataAccessors[name]
        };
      }
    }
    if (handleUnknown === 'null') {
      if (!IS_PRODUCTION) console.warn('unknown accessData accessor: ' + name);
      return null;
    } else {
      throw new Error('AccessServicePlugin has no dataAccessor for data with name "' + name + '"');
    }
  }
  assertPluginIsReady() {
    return Promise.resolve(true);
  }
  getAuthInfo() {
    const sess = this._sessionInfo$.value?.session;
    if (!sess) return null;
    return {
      authType: sess.authType,
      authId: sess.authId
    };
  }
  setSessionId(sessionId) {
    if (sessionId === this._sessionId) return Promise.resolve(true);
    if (typeof sessionId !== 'string') {
      this.endSession();
      return Promise.resolve(false);
    }
    this._sessionId = sessionId; // important to do this immediately so that it is available for interceptors to define Bearer Header!
    return this.loadAndInitializeSession(sessionId).then(info => {
      const sessionIdMatches = info?.session?.sessionId === sessionId;
      if (!sessionIdMatches) console.warn('Session scope is invalid for this platform. Ending session.');
      return sessionIdMatches;
    });
  }
  checkSessionScope(requiredScope) {
    const session = this._sessionInfo$.value?.session;
    return session && session.scope === requiredScope;
  }
  endSession() {
    // possibly called multiple times, so check if a session is actually there to end.
    if (!this._sessionId) return true;
    this.events.next(new OnSessionEnd());
    this._sessionId = null;
    this._sessionInfo$.next(null);
    return Promise.resolve(true);
  }
  hasSessionAlreadyLoaded(sessionId) {
    return this._sessionId && this._sessionId.length > 0 && sessionId === this._sessionId && !!this.sessionInfo;
  }
  /**
   * TODO: #86c0agb3p - to be replaced by checks against role and abilities.
   * Refactoring: Move to a bundled subobject... pass this.permissions / sessionInfo$
   */
  hasRole(...roles) {
    // TODO: #86c0agb3p - temporary implementation 
    const grantedRole = calculateRoleFromLegacyPermissions(this.isLoggedIn, this.permissions);
    for (const role of roles) {
      if (roleCheck(role, grantedRole)) return true;
    }
    return false;
  }
  hasAnyPermission(permissions) {
    return PermissionsHelper.hasAnyPermission(this.permissions, permissions);
  }
  hasAllPermissions(permissions) {
    return PermissionsHelper.hasAllPermissions(this.permissions, permissions);
  }
}